import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Logo from './logo.png'
import { countries} from 'countries-list'
import { MultiSelect } from "react-multi-select-component";
import { Alert, Form, Button, Container, Row, Col, Card} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Checkout = () => {


  const [selected, setSelected] = useState([]);

  const {uuid}=useParams()
  const [data,setData]=useState({})
  const [isLoading,setIsLoading]=useState(true)
  const [err,setErr]=useState(false)
  const [appCountries,setAppCountries]=useState([])
  const [record,setRecord]=useState({
    FullName:'',
    VatNumber:'',
    Password:'',
    Email:'',
    Country:'',
    Telephone:'',
    telPrefix:''

  })

  useEffect(()=>{
    loadData()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const loadData=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopproducts/'+uuid)
    .then(res=>{
      if (res.data.err===0){
        if (res.data.results.length===1){
          setErr(false)
          setData(res.data.results[0])
          loadCountries()
          if (sessionStorage.getItem('record')){
            setRecord(JSON.parse(sessionStorage.getItem('record')))
            if(JSON.parse(sessionStorage.getItem('record')).appCountries){
              setSelected(JSON.parse(sessionStorage.getItem('record')).appCountries)
            }
          }
        }else{
          setErr(true)
        }
      }else{
        setErr(true)
      }
      setIsLoading(false)
    }).catch(xerr=>{
        setErr(true)
        setIsLoading(false)
    })          
}

const loadCountries=()=>{
  setIsLoading(true)
  axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopcountries')
  .then(res=>{
    if (res.data.err===0){

        setAppCountries(res.data.results)

    }else{
      setErr(true)
    }
    setIsLoading(false)
  }).catch(xerr=>{
      setErr(true)
      setIsLoading(false)
  })          
}



const countriesList=Object.entries(countries).sort((a,b)=>{
  if (a[1].name < b[1].name) {
      return -1;
  }
  if (a[1].name > b[1].name) {
      return 1;
  }
  return 0;       
}).map((item,index)=>{
 // console.log(item)
  return(
      <option key={'country'+index} value={item[1].name}>{item[1].name}</option>

  )
})


const appCountriesList=appCountries.map((item,index)=>{

  return(

      {label:item.Country, value:item.CountryID}
  )
})

const register=(e)=>{
  e.preventDefault()
  //console.log(selected)
  axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopproducts/checkemail/'+record.Email).then(xres=>{
    if (xres.data.err===0){
      axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopproducts/paymentlink/'+uuid)
      .then(res=>{
          if (res.data.err===0){
            if (data.NumberOfCountries>0){
              var regObj={...record,LicenceDurationMonths:data.LicenceDurationMonths,appCountries:selected}
            }else{
             regObj={...record,appCountries:appCountriesList}
            }
            
            //console.log(regObj)
            sessionStorage.setItem('record',JSON.stringify(regObj))
            window.location.href=res.data.url
          }else{
  
              alert('ERROR: '+res.data.msg.code) 
          }
      }).catch(err=>{
  
          console.log(err)
      })   
    }else{
      alert('Email exists.')
    }
  })
       

}

const selectCountry=(countryName)=>{
  
  try{
    var telPrefix=Object.entries(countries).filter(item=>item[1].name===countryName)[0][1].phone[0]
  }catch(err){
     telPrefix=''
  }
  setRecord({...record,Country:countryName,telPrefix:telPrefix})

}

useEffect(()=>{
  
  if (selected.length>data.NumberOfCountries){
    var sArr=selected
    sArr.pop()
    setSelected([...sArr])
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[selected])


  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {   
    setShowPassword(!showPassword);
  };

  const transformDescr = (descr) => {
    if (!descr) return "";
    return descr
      .replace(/<li>/g, `<div style="display: flex; align-items: start;">
        <span style="margin-right:10px; font-size:1.2rem; color:#ff5d25"></span>
        <div style="display: inline-block;"> <strong>`)
      .replace(/<\/li>/g, `</strong></div></div>`);
  };
  
  
    // �Ʊ��� Ŀ padding-bottom ��� �ǵ� ��� Ŀ body, ���� ��� ķ� checkout page 
    useEffect(() => {
      // ���ø��� ķ� ���÷� "checkout-page" �Ŀ <html> ��� <body> ��Ĭ Ŀ mount
      document.documentElement.classList.add('checkout-page');
      document.body.classList.add('checkout-page');
    
      return () => {
        // �Ʊ���÷ ķ� ���÷� "checkout-page" ��Ĭ Ŀ unmount
        document.documentElement.classList.remove('checkout-page');
        document.body.classList.remove('checkout-page');
      };
    }, []);

  return (
    
    <div  style={{background: "url('/bg-checkout.jpg') no-repeat center top", backgroundSize: "cover"}}>
            <div className="header-container" style={{ 
              position: "relative",
              width: "100vw",
              left: "50%", 
              transform: "translateX(-50%)", // ��Ĺ�����Ĭ ı margins
              overflow: "hidden"
            }}>
              <div 
                className="background-wrapper" 
                style={{
                  background: `url("/orange.jpg")`,
                }}>
                  <img src={Logo} alt="logo" style={{position:'absolute', width:'80px', right: '10%', top:'20%'}} />
              </div>
            </div>
      <Container  className="d-flex align-items-center justify-content-center mt-0">

        {err &&
          <Alert variant="danger">An error has Occured</Alert>
        }

        {isLoading &&
          <Alert variant="warning">Loading, please wait</Alert>
        }
                
        {!isLoading &&
          <>
            <Row className="w-100 d-flex p-0 mt-5">  
              {/* Left Text Section */}
              <Col md={6} className=" d-md-flex flex-column  p-4">
                  <Card
                style={{
                  background: 'rgb(0 0 0 / 60%)',
                  backdropFilter: 'blur(2px)',
                  border: '1px solid #72676729',
                  borderRadius: '12px',
                  padding: '20px',
                  color: 'white',
                  maxWidth:'500px',
                  width:"100%",
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }} className="mb-4 p-4">
              
                  <h2 className="fw-bold mb-4">{data.SportName} <br></br> {data.Title} </h2>
                  <div className="pack" dangerouslySetInnerHTML={{ __html: transformDescr(data.Descr) }} />


                </Card>
              </Col>

              {/* Right Form Section */}
              <Col xs={12} md={6} className="d-flex justify-content-center align-items-center p-4 pb-5">
                <Card className="p-4 shadow-sm" style={{ maxWidth: "500px", width: "100%", borderRadius: "12px" }}>
                  <h5 className="mb-3 text-center">Create your 123High account</h5>
                  <Form onSubmit={register}>

                    <Form.Group className="mb-3">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={record.FullName}
                        onChange={(e)=>setRecord({...record,FullName:e.target.value})}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>VAT</Form.Label>
                      <Form.Control
                        type="text"
                        name="vat"
                        value={record.VatNumber}
                        onChange={(e)=>setRecord({...record,VatNumber:e.target.value})}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                        <Form.Control required as="select" value={record.Country} onChange={(e)=>selectCountry(e.target.value)}>  
                          <option value=''>[Select Country]</option>{countriesList}
                        </Form.Control>
                      </Form.Group>            

                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={record.Email} 
                        onChange={(e)=>setRecord({...record,Email:e.target.value})}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={record.Telephone} 
                        onChange={(e)=>setRecord({...record,Telephone:e.target.value})}
                        required
                        />
                    </Form.Group>

                      <Form.Group className="mb-3 position-relative">
                      <Form.Label>Password</Form.Label>

                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={record.Password} 
                          onChange={(e)=>setRecord({...record,Password:e.target.value})}
                          placeholder="Enter password"
                          minLength={6}
                          maxLength={12}
                          required
                          />
                          <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          onClick={togglePasswordVisibility}
                          style={{position:'absolute', right: "12px", top: '60%',color: 'gray'}}
                          />
                    </Form.Group>

                      {data.NumberOfCountries>0 &&
                        <Form.Group className="mb-3" >
                          <Form.Label>Access to users' countries <i>(select {data.NumberOfCountries} countries)</i></Form.Label>
                          <MultiSelect hasSelectAll={false} options={appCountriesList} value={selected} onChange={setSelected} labelledBy="Select"  />
                        </Form.Group>
                      }
                      <span className='text-muted' style={{ fontSize: 10, paddingTop:"25px" }}>*By completing this payment, you agree to our website's Terms of Use.</span>
                      <Button size="lg" className="w-100 mt-1 btn-payment" type="submit">
                      Proceed to payment via Stripe {data.Price}€
                      </Button>
                  </Form>
                </Card>
              </Col>
            </Row>
         </>
        }
      </Container>
  </div>

  );
};

export default Checkout;
