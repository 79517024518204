import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { UserContext } from './context/UserContext'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from './logo.png'
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

export default function Login() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const [loginError,setLoginError]=useState(false)
  const [loginSuccess,setLoginSuccess]=useState(false)
  const [msg,setMsg]=useState('')
  const [isLoading,setIsLoading]=useState(false)
  const {userToken,setUserToken} = useContext(UserContext)
  const {userName,setUserName}=useContext(UserContext)
  const {usrPhoto,setUsrPhoto}=useContext(UserContext)
  const {socket,setSocket}=useContext(UserContext)
  const Email=useRef('')
  const Password=useRef('')

  const navigate=useNavigate ()

  useEffect(()=>{
    if (sessionStorage.getItem('Email')){
      sessionStorage.getItem('Email')
      sessionStorage.getItem('Password')
      login(sessionStorage.getItem('Email'),sessionStorage.getItem('Password'))
    }

  },[])




  const login=(Email,Password)=>{
    const loginObj={
      Email,
      Password
    }
    sessionStorage.removeItem('Email')
    sessionStorage.removeItem('Password')

    axios.post(process.env.REACT_APP_API_SERVER+'manager/login',loginObj).then(res=>{
      if (res.data.err==0){
        console.log(res.data.usr)
        setIsAuth(true)
        setUserToken(res.data.usr.CustomerUUID)
        setUserName(res.data.usr.Name)
        setLoginSuccess(true)
        setLoginError(false)
        setApiToken(res.data.usr.token)

        setUsrPhoto(`${process.env.REACT_APP_API_SERVER}files/${res.data.usr.ChatUserUUID}/${Date.now()}`)
        sessionStorage.setItem('isAuth',1)
        sessionStorage.setItem('userToken',res.data.usr.CustomerUUID)
        sessionStorage.setItem('userName',res.data.usr.Name)
        sessionStorage.setItem('mngapiToken',res.data.usr.token)
        sessionStorage.setItem('ProductTitle',res.data.usr.Title)
        sessionStorage.setItem('SportID',res.data.usr.SportID)
        sessionStorage.setItem('appCountries',JSON.stringify(res.data.usr.appCountries))
        sessionStorage.setItem('ChatUserUUID',res.data.usr.ChatUserUUID)

        if (res.data.usr.ChatUserUUID.length>0){
          socket.emit('user-connected',res.data.usr.ChatUserUUID)
        }
        


        setTimeout(() => {

          navigate('/dashboard')
      }, 3000); 
      }else{
        setIsAuth(false)
        // setUserToken(null)
        // setLoginSuccess(false)
        setMsg(res.data.msg)
        setLoginError(true)  
        setTimeout(() => {
            setLoginError(false) 
        }, 3000);            
      }
    })
  }

  const submit=(e)=>{
    e.preventDefault()
    login(Email.current.value,Password.current.value)

  }

  // �Ʊ��� Ŀ padding-bottom ��� �ǵ� ��� Ŀ body, ���� ��� ķ� login page 
  useEffect(() => {
    // ���ø��� ķ� ���÷� "login-page" �Ŀ <html> ��� <body> ��Ĭ Ŀ mount
    document.documentElement.classList.add('login-page');
    document.body.classList.add('login-page');
  
    return () => {
      // �Ʊ���÷ ķ� ���÷� "login-page" ��Ĭ Ŀ unmount
      document.documentElement.classList.remove('login-page');
      document.body.classList.remove('login-page');
    };
  }, []);
  
  
  

  return (

    <div className="maincontainer bg-md-login">
        <div className="container-fluid ">
            <div className="row no-gutter">
               
                <div className="col-md-6 d-none d-md-flex bg-image" style={{backgroundImage:'url("burn-ball.jpg")', backgroundPosition:"center", backgroundSize:'cover'}}></div>
                
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center" style={{minHeight:"100vh"}}>
                  <Card style={{paddingTop:'30px', paddingBottom:'30px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
                        <div className="text-center py-3"> 
                            <img 
                                src={Logo} 
                                alt="123High Logo" 
                                style={{ 
                                    width: '100px', 
                                    height: 'auto' 
                                  }}
                                  className="img-fluid"
                                  />
                        </div>
                        <div className="d-flex align-items-center">      
                          <div className="container">
                              <div className="row p-5">
                                  <div className="col-lg-12  mx-auto">
                                      <h5 className='display-7 text-center'>manager </h5>
                                      <h5 className='display-7 text-center' style={{wordBreak:"keep-all"}}>onetwothreehigh.com</h5>
                                      <p className="text-muted mt-5 mb-4" style={{maxWidth:'350px', fontSize:'13px'}}><i>Built for coaches, admins, talent scouts and sports leaders  engineered for greatness.</i></p>
                                      <form onSubmit={submit}>
                                          <div className="mb-3">
                                              <input id="inputEmail" type="email" placeholder="Email address" required ref={Email} autofocus="" className="form-control rounded-pill border-0 shadow-sm px-4" controlId="formBasicEmail" />
                                          </div>
                                          <div className="mb-3">
                                              <input id="inputPassword" type="password" placeholder="Password" required ref={Password} className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" controlId="formBasicPassword"/>
                                          </div>
                                          {/* <div className="form-check">
                                              <input id="customCheck1" type="checkbox" checked className="form-check-input" />
                                              <label for="customCheck1" className="form-check-label">Remember password</label>
                                          </div> */}
                                          <div className="d-grid gap-2 mt-2">
                                          <button type="submit" className="btn  btn-block text-uppercase mb-2 rounded-pill shadow-sm" style={{backgroundColor:"#7369eb", color:"white"}} disabled={loginSuccess}>Login</button>
                                          </div>
                                          
                                      </form>
                                  </div>
                              </div>

                              {loginSuccess && 
                                  <Alert variant="success">
                                  Welcome {userName}. Please wait...
                                  </Alert>                              
                              } 

                              {loginError && 
                                  <Alert variant="danger">
                                  {msg}
                                  </Alert>                              
                            }  
                          </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
      </div>

    // <Container >
    //       <br/>

    //       <Card style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0',padding:'30px'}}>

    //       <Card.Body>
    //           <Card.Title>
    //           <Alert variant="success" style={{backgroundColor:'orange'}}>
    //             <table width="100%">
    //               <tr>
    //                   <td width="120" align="center"><img src={Logo} height="100"/></td>
    //                   <td width="20"></td>
    //                   <td valign="middle" align="center"><h3>manager.onetwothreehigh.com</h3></td>
    //                   <td width="140"></td>
    //               </tr>
    //             </table>
                
                
    //             </Alert>
    //           </Card.Title>
    //           <Card.Text>

    //               <Form onSubmit={submit}>
    //               <Form.Group className="mb-3" controlId="formBasicEmail">
    //                 <Form.Label>Email address</Form.Label>
    //                 <Form.Control type="email" required ref={Email}/>
    //               </Form.Group>

    //               <Form.Group className="mb-3" controlId="formBasicPassword">
    //                 <Form.Label>Password</Form.Label>
    //                 <Form.Control type="password" required ref={Password} />
    //               </Form.Group>
    //               <p align="right">
    //               <Button variant="dark" type="submit" style={{width:'200px'}} disabled={loginSuccess}>
    //                 Login
    //               </Button>
    //               </p>
    //             </Form>
    //           </Card.Text>

    //           <Card.Footer>
    //           {loginSuccess && 
    //               <Alert variant="success">
    //               Welcome {userName}. Please wait...
    //               </Alert>                              
    //           } 

    //           {loginError && 
    //               <Alert variant="danger">
    //               {msg}
    //               </Alert>                              
    //         }  
    //           </Card.Footer>
    //       </Card.Body>
    //       </Card>
       


             



    // </Container>




  )
}
